import { useState } from "react";
import PropTypes from "prop-types";
import IconArrow from "../../../components/icons/svgs/arrow.svg";
import useResponsiveWithHydrationFix from "../../../hooks/useResponsiveWithHydrationFix";
import * as Styles from "./materialCard/styles.module.css";

function MaterialCard({ img, title, desc, info, alt }) {
  const [isFlipped, setIsFlipped] = useState(false);
  const imgSrc = useResponsiveWithHydrationFix(img ? img.mobile : null, img ? img.desktop : null);
  return (
    <div className={Styles.material_card}>
      <div>
        <img className={Styles.material_card__image_container__image} src={imgSrc} alt={alt} />
      </div>
      <div className={Styles.material_card__content}>
        {isFlipped ? (
          <>
            <p className="al_subtext">{info}</p>
            <button
              className={Styles.material_card__content__info_button}
              onClick={() => setIsFlipped(false)}
            >
              <IconArrow
                width="7"
                height="13"
                className={Styles.material_card__content__info_button__back_arrow}
              />
              <p className="al_link">Back</p>
            </button>
          </>
        ) : (
          <>
            <h4 className="al_h4">{title}</h4>
            <p className="al_p">{desc}</p>
            <button
              className={Styles.material_card__content__info_button}
              onClick={() => setIsFlipped(true)}
            >
              <p className="al_link">See care info</p>
              <IconArrow width="7" height="13" />
            </button>
          </>
        )}
      </div>
    </div>
  );
}

MaterialCard.propTypes = {
  img: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default MaterialCard;
